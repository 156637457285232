import React, { useEffect, useState } from 'react';
import Lander from "./pages/lander";
import { Route, Switch, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as AppActions from "./redux/app-contract/appContractActions";
import { RootState } from './redux/rootReducer';
import { signInUser } from './_apis/vesting';
import toast, { Toaster } from "react-hot-toast";
import { setSmartContractAddress } from "../src/utils/const.utils";



function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRouteContractAddress: any = location.pathname.split("/").pop();
  const mainContractAddress = useSelector((state: RootState) => state.mainAppContract.mainContract);
  const [smartContractAddressParam, setSmartContractAddressParam] = useState('');

  useEffect(() => {
    console.log(location.search)
    const urlParams = new URLSearchParams(location.search);
    let sParam: string = urlParams.get('smartContractAddress') ?? '';
    if (!sParam) {
      toast.error('Missing smart contract address from url')
    }
    setSmartContractAddress(sParam);
    setSmartContractAddressParam(sParam);
    console.log("smartContractAddress", sParam ?? '')
    dispatch(
      AppActions.contractAddressOfApp(currentRouteContractAddress)
    );
  })

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {smartContractAddressParam &&
        <Switch>
          <Route path={`/`} component={Lander}></Route>
        </Switch>
      }
    </>
  );
}

export default App;
